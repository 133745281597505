let hamburger = document.getElementById("hamburger");
let mainNav = document.getElementById("main-nav");
let navList = mainNav.querySelector("ul");

if (hamburger) {
  hamburger.addEventListener("click", (e) => {
    // console.log(e.target);
    console.log(hamburger);
    hamburger.classList.toggle("is-active");
    if (mainNav) {
      let isOpen = hamburger.classList.contains("is-active");
      console.log(navList);
      if (isOpen) {
        navList.classList.remove("pl-10");
        navList.classList.add("pl-4");
      } else {
        navList.classList.add("pl-10");
        navList.classList.remove("pl-4");
      }
      mainNav.className = isOpen
        ? "absolute ease-in-out transition-[padding,margin,opacity,max-width] opacity-100 duration-500 lg:static overflow-hidden right-0 top-0 bottom-0 w-screen min-h-svh h-max max-w-80 lg:max-w-none lg:h-min lg:min-h-fit lg:w-min bg-emerald-50/95 lg:bg-transparent"
        : "absolute ease-in-out transition-[padding,margin,opacity,max-width] opacity-20 lg:opacity-100 duration-500 lg:static right-0 top-0 bottom-0 w-screen min-h-svh h-max max-w-0 overflow-hidden lg:max-w-none lg:h-min lg:min-h-fit lg:w-min bg-emerald-50/95 lg:bg-transparent";
    }
  });
}
